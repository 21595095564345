a {
  position: static;
  left: 13.99%;
  right: 13.99%;
  top: 26%;
  bottom: 26%;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  letter-spacing: 0.5px;

  color: $grey-mid;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-decoration: none;
  &:hover {
    color: $accent;
  }
}
