@import "./colors.scss";

.companies-div {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  align-items: center;
  justify-content: center;
  width: 670px;
  font-size: large;
}

.company-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.company-name {
  width: 40%;
}

.company-div-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.company-name-title {
  width: 40%;
  color: $grey-mid;
}

.company-title {
  width: 20%;
  color: $grey-mid;
  text-align: center;
}

.company-users {
  text-align: center;
  width: 20%;
}
